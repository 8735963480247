/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;

@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "@angular/material/theming";
// always include only once per project
@include mat.core();
@import "theme/reset";
@import "theme/vars";

@import "theme/grid";
@import "theme/content-layout";
@import "theme/content-style";
@import "theme/form";

// import our custom theme
@import "theme/rci-theme.scss";

// specify theme class eg: <body class="my-theme"> ... </body>
.rci-theme {
  // use our theme with angular-material-theme mixin
  @include mat.all-component-themes($rci-theme);
}
