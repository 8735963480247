@use '@angular/material' as mat;
@import "@angular/material/theming";

@import "palette";

@import "../components/icons/icon/mat-icon-theme";

@import "../components/forms/textfield/textfield-theme";
@import "../components/forms/select/select-theme";
@import "../components/forms/checkbox/checkbox-theme";
@import "../components/forms/radio-button/radio-theme";
@import "../components/forms/datepicker/datepicker-theme";
@import "../components/forms/textarea/textarea-theme";
@import "../components/forms/progress-bar/progress-bar-theme";

@import "../components/navigation/button/button-theme";
@import "../components/navigation/stepper/stepper-theme";
@import "../components/structure/nav-menu/nav-list-theme";
@import "../components/navigation/tabs/tab-theme";
@import "../components/navigation/collapse-link/expansion-panel-theme";

@import "../components/lists/link-item/list-item-theme";
@import "../components/tables/table/table-theme";

@import "../components/modals/dialog/mat-dialog-theme";

/*$general-typography: mat-typography-config(
  $font-family: "Arial, sans-serif",
  $body-2: mat-typography-level(14px, 24px, 600)
);

$secondary-typography: mat-typography-config(
  $font-family: "Arial, sans-serif"
);*/

$custom-typography: mat.define-typography-config(
  $font-family: "Arial, sans-serif",
  $display-1: mat.define-typography-level(24px, 30, 400, 0),
  $headline: mat.define-typography-level(22px, 28px, 400, 0),
  $title: mat.define-typography-level(18px, 20px, 400, 0),
  $body-1: mat.define-typography-level(16px, 20px, 400, 0),
  $body-2: mat.define-typography-level(14px, 20px, 700, 0),
  $caption: mat.define-typography-level(13px, 18px, 400, 0px)
);

@include mat.core($custom-typography);

@include mat.all-component-typographies($custom-typography);

$rci-theme: mat.define-light-theme(
  mat.define-palette($rci-primary, 700),
  mat.define-palette($rci-accent, 700),
  mat.define-palette($rci-warn, 700)
);

@include mat.all-component-themes($rci-theme);

@include mat.icon-theme($rci-theme);
@include button-theme($rci-theme);
@include stepper-theme($rci-theme);
@include textfield-theme($rci-theme);
@include select-theme($rci-theme);
@include radio-theme($rci-theme);
@include checkbox-theme($rci-theme);
@include datepicker-theme($rci-theme);
@include textarea-theme($rci-theme);
@include mat.dialog-theme($rci-theme);
@include nav-list-theme($rci-theme);
@include list-item-theme($rci-theme);
@include tab-theme($rci-theme);
@include expansion-panel-theme($rci-theme);
@include progress-bar-theme($rci-theme);
@include table-theme($rci-theme);
@include mat-dialog-theme($rci-theme);
