@mixin tab-theme($theme) {
  .app-tabs {
    mat-tab-group {
      .mat-tab-header {
        //border-bottom-color: $grey-30;
        border-bottom: none;

        .mat-tab-label {
          @extend %small-title-bold;
          color: $grey_90;
          opacity: 1;
          height: 55px;
          min-width: 90px;
        }
      }
      &.is-desktop.with-more {
        .mat-tab-header-pagination {
          display: none !important;
        }
      }
    }
  }

  .mat-tab-links {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
  }
}
