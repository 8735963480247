@mixin stepper-theme($theme) {
  //$primary: map-get($theme, primary);

  app-stepper {
    padding-top: 24px;
    display: block;
    .mat-stepper-horizontal {
      background: none;
    }
    .mat-horizontal-stepper-header-container {
      width: 100%;
      margin: auto;
    }

    .mat-step-header-ripple.mat-ripple {
      display: none;
    }

    //we removed [dir="rtl"]; could be added here
    .mat-stepper-label-position-bottom {
      .mat-horizontal-stepper-header:first-child:before,
      .mat-horizontal-stepper-header:last-child:after,
      .mat-horizontal-stepper-header:not(:first-child)::before,
      .mat-horizontal-stepper-header:not(:last-child)::after {
        content: "";
        top: 23px;
        width: 50%;
        border-top-width: 5px;
        border-top-color: $grey-30;
        background: #fff;
        z-index: 1;
      }
      .mat-horizontal-stepper-header:first-child:before,
      .mat-horizontal-stepper-header:last-child:after {
        border-top-style: solid;
        position: absolute;
      }
      .mat-horizontal-stepper-header:first-child:before {
        left: 0;
      }
      .mat-horizontal-stepper-header:last-child:after {
        right: 0;
      }

      .mat-horizontal-stepper-header {
        padding: 10px 24px;

        &:hover,
        &:focus {
          background: none;
        }
        &:focus,
        &.cdk-focused {
          .mat-step-label:active {
            color: $color-accent;
          }
        }
      }

      .mat-horizontal-stepper-header.done:after,
      .mat-horizontal-stepper-header.done:before,
      .mat-horizontal-stepper-header[aria-selected="true"]:before {
        border-top-color: $color-accent;
      }

      .mat-horizontal-stepper-header.done {
        .mat-step-label {
          color: $color-accent;
        }
      }
      .mat-horizontal-stepper-header[aria-selected="true"]:last-child:after {
        border-top-color: $color-accent;
      }
      .mat-horizontal-stepper-header[aria-selected="true"]:before,
      .mat-horizontal-stepper-header[aria-selected="true"]:last-child:after {
        border-top-color: $color-accent;
      }
    }
    //link decoration for the next step, the one after the active step
    //uncomment if you want it to be clickable
    /*.mat-horizontal-stepper-header[aria-selected="true"]
      + div
      + .mat-horizontal-stepper-header {
      .mat-step-icon {
        cursor: pointer;

        & + .mat-step-label {
          cursor: pointer;

          &:hover {
            color: $color-accent;
          }
        }

        &:hover + .mat-step-label {
          color: $color-accent;
        }
      }
    }*/

    .mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
      display: none;
    }

    .mat-step-header {
      cursor: default;
      width: 33.33%;

      .mat-step-icon {
        background-color: $white;
        color: $grey-90;
        border-radius: 50%;
        height: 30px;
        width: 30px;
        border: 1px solid $grey-90;
        flex-shrink: 0;
        position: relative;
        z-index: 2;
        @extend %text-body;
        font-weight: 700;

        &.mat-step-icon-state-edit,
        &.mat-step-icon-state-done,
        &.mat-step-icon-selected {
          border-color: $color-accent;
          color: $color-accent;
        }

        &.mat-step-icon-state-edit {
          // background-color: $g1;
          //link decoration
          cursor: pointer;

          & + .mat-step-label {
            cursor: pointer;

            &:hover {
              color: $color-accent;
              text-decoration: underline !important;
            }
          }

          &:hover + .mat-step-label {
            color: $color-accent;
            text-decoration: underline;
          }
        }
      }
    }

    .mat-step-header {
      .mat-step-label {
        @extend %text-small;
        overflow: visible;
        text-overflow: clip;
        color: $grey-90;
        min-width: 20px;
        line-height: 1em;
        cursor: default;

        // .mat-step-label.mat-step-label-active,
        &.mat-step-label-selected {
          color: $color-accent;
          font-weight: 400;
        }
      }
      //we must hide the icons and text create and done
      //and we add the number 1,2,3  by css
      //as it seems there's no easy other way to do it.

      .mat-icon {
        visibility: hidden;
      }
      span.ng-star-inserted {
        display: none;
      }
      .mat-step-icon {
        position: relative;
        &:before {
          content: "1";
          visibility: visible;
          position: absolute;
          width: 28px;
          text-align: center;
          top: 4px;
          @extend %text-body;
          font-weight: 700;
        }
      }
      &:nth-of-type(2) {
        .mat-step-icon {
          &:before {
            content: "2";
          }
        }
      }
      &:nth-of-type(3) {
        .mat-step-icon {
          &:before {
            content: "3";
          }
        }
      }
    }

    .mat-stepper-label-position-bottom
      .mat-horizontal-stepper-header
      .mat-step-label {
      padding: 5px 0 0;
      margin: 0;
      @media screen and (max-width: $breakpoint-xs) {
        display: none;
      }
    }
  }
}
