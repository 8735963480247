%body-font-family {
  font-family: arial, sans-serif;
}

%text-small {
  font-size: 13px;
  line-height: 18px;
}
%text-body {
  font-size: 14px;
  line-height: 20px;
}
%text-subtitle {
  font-size: 16px;
  line-height: 20px;
}
%text-title {
  font-size: 18px;
  line-height: 20px;
}
%text-headline {
  font-size: 22px;
  line-height: 28px;
}
%text-display {
  font-size: 24px;
  line-height: 30px;
}

%color-white {
  color: $white;
}
%color-50 {
  color: $grey-50;
}
%color-70 {
  color: $grey-70;
}
%color-90 {
  color: $grey-90;
}
%color-primary {
  color: $color-primary;
}
%color-warn {
  color: $color-warn;
}
%color-accent {
  color: $color-accent;
}

%text-cap {
  text-transform: uppercase;
}
%text-regular {
  font-weight: 400;
}
%text-bold {
  font-weight: 700;
}
%strong {
  font-weight: 700;
  color: $grey-90;
}

%text-small-bold {
  @extend %text-small;
  @extend %text-bold;
}
%text-body-bold {
  @extend %text-body;
  @extend %text-bold;
}
%text-subtitle-bold {
  @extend %text-subtitle;
  @extend %text-bold;
}
%text-title-bold {
  @extend %text-title;
  @extend %text-bold;
}
%text-display-bold {
  @extend %text-display;
  @extend %text-bold;
}

%small-cap {
  @extend %text-small;
  @extend %text-cap;
}
%body-cap {
  @extend %text-body;
  @extend %text-cap;
}

%subtitle-cap {
  @extend %text-subtitle;
  @extend %text-cap;
}
%title-cap {
  @extend %text-title;
  @extend %text-cap;
}
%small-cap {
  @extend %text-small;
  @extend %text-cap;
}
%small-cap-bold {
  @extend %small-cap;
  @extend %text-bold;
}
%body-cap-bold {
  @extend %text-body;
  @extend %text-cap;
  @extend %text-bold;
}

%subtitle-cap-bold {
  @extend %text-subtitle;
  @extend %text-cap;
  @extend %text-bold;
}
%title-cap-bold {
  @extend %text-title;
  @extend %text-cap;
  @extend %text-bold;
}
%title-cap-bold-primary {
  @extend %text-title;
  @extend %text-cap;
  @extend %text-bold;
  @extend %color-primary;
}
%title-cap-accent {
  @extend %text-title;
  @extend %text-cap;
  @extend %color-accent;
}
@mixin text-underlined(
  $color: $grey-90,
  $align: left,
  $bottom: 13px,
  $thickness: 3px,
  $width: 30px
) {
  position: relative;
  text-align: $align;
  @if ($bottom != auto and $bottom >= 0) {
    padding-bottom: $bottom;
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    background-color: $color;
    width: $width;
    height: $thickness;
    pointer-events: none;
    @if ($align == left) {
      left: 0;
    } @else if ($align == center) {
      left: 50%;
      margin-left: - calc($width / 2)
    }
    @if ($bottom == auto) {
      bottom: auto;
    } @else {
      bottom: 0;
    }
    @if ($bottom != auto and $bottom < 0) {
      bottom: -$bottom;
    }
  }
}

@mixin title-underlined(
  $color: $grey-90,
  $align: left,
  $bottom: 13px,
  $thickness: 3px,
  $width: 30px
) {
  @include text-underlined($color, $align, $bottom, $thickness, $width);
  color: $grey-90;
}

@mixin link-underlined($color: inherit, $thickness: 1px, $bottom: 0) {
  @include title-underlined($color, left, $bottom, $thickness, 100%);
}
%title-underlined {
  @extend %title-cap-bold;
  @include title-underlined();
}
%title-underlined-accent {
  @extend %title-cap-bold;
  @include title-underlined($color-accent);
}
%title-underlined-centered {
  @extend %title-cap-bold;
  @include title-underlined($grey-90, center);
}

%title-underlined-centered-accent {
  @extend %title-cap-bold;
  @include title-underlined($color-accent, center);
}
%small-title-underlined-accent {
  @extend %small-cap-bold;
  @include title-underlined($color-accent, left, 13px, 2px);
}

%app-title {
  @extend %title-cap-bold;
  color: $grey-90;
}
%app-subtitle {
  @extend %subtitle-cap;
  color: $grey-90;
}
%app-heading {
  @extend %body-cap;
  color: $grey-90;
}

%small-title {
  @extend %small-cap;
}
%small-title-bold {
  @extend %small-cap-bold;
}

%small-block-title {
  @extend %small-cap-bold;
  color: $grey-90;
  margin-bottom: 5px;
}
%small-block-subtitle {
  @extend %small-cap-bold;
  color: $grey-70;
  margin-bottom: 5px;
}
%small-block-subtitle-light {
  @extend %small-cap;
  color: $grey-90;
  margin-bottom: 5px;
}

@mixin line-clamp($numLines: 1, $lineHeight: 1.428) {
  overflow: hidden;
  text-overflow: -o-ellipsis-lastline;
  text-overflow: ellipsis;
  display: block;
  /* autoprefixer: off */
  display: -webkit-box;
  -webkit-line-clamp: $numLines;
  -webkit-box-orient: vertical;
  max-height: $numLines * $lineHeight + unquote("em");
}
