/*$breakpoint_lg: 1326px;
$breakpoint_mdl: 1120px;
$breakpoint_md: 1024px;
$breakpoint_smd: 896px;
$breakpoint_sm: 768px;
$breakpoint_xs: 480px;
$custom-breakpoint-mobile: 620px;*/

.app-page {
  .grid-item {
    .content-box {
      background: $white;
    }
  }
}

.rwd-row {
  &:after {
    content: "";
    display: block;
    clear: both;
  }
  .rwd-col-50 {
    width: 100%;
    @extend %responsive-padding-half;
    @media screen and (min-width: $breakpoint-sm + 1) {
      float: left;
      width: 50%;
    }
  }
  margin-left: -15px;
  margin-right: -15px;
  @media screen and (max-width: $breakpoint-md) {
    margin-left: -12px;
    margin-right: -12px;
  }
  @media screen and (max-width: $breakpoint-xs) {
    margin-left: -7.5px;
    margin-right: -7.5px;
  }
}

//PAGE LAYOUT WITH MASONRY
.no-mansory {
  height: 300vh;
  overflow: hidden;
  margin-bottom: -300vh;
}

* {
  box-sizing: border-box;
}

/* clearfix */

.grid:after,
.grid--nested:after,
.content-box:after {
  content: "";
  display: block;
  clear: both;
}

.container {
  padding: 15px;
  min-width: 320px;
}

.app-page.no-log {
  .container {
    background: #fff;
  }
}
.app-page:not(.no-left-column) {
  .container {
    padding-left: 261px; //216 + 15 + 30
    .grid {
      position: relative;
    }
    @media screen and (max-width: $breakpoint-mdl) {
      padding-left: 15px;
    }
    .grid:before {
      content: "";
      display: block;
      height: calc(100% - 30px);
      width: 216px;
      background-color: #fff;
      position: absolute;
      left: -231px; //261-30
      top: 15px;
    }
  }

  /*  white background column, positon absolute,  fitting the height of the content*/
  // .container:not(.login-container)
}
.app-footer,
.app-header {
  min-width: 320px;
}

.content-box--form {
  .content-box-inner-section {
    margin: 0 auto;
    max-height: 100%;
    max-width: 100%;
  }
}

.content-box.content-box--welcome {
  @media screen and (max-width: $breakpoint-sm) {
    background: none !important;
  }
}

.content-box-inner-section {
  margin: 0;
  max-height: 100%;
  max-width: 100%;
  width: 100%;
}

.line-sep {
  height: 1px;
  width: 100%;
  background-color: $grey-50;
  margin-top: 15px;
  margin-bottom: 15px;
}

.container.login-container {
  min-height: 100%;
  padding: 0;
  .grid {
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;
    max-width: none;
    z-index: 5000;
    margin-top: 0;
    margin-bottom: 0;
    min-height: 100%;
  }
  .content-box,
  .content-box--background-image {
    height: 100%;
  }
  .grid-item {
    padding: 0;
    float: none;
  }

  @media screen and (min-width: $breakpoint-sm + 1) {
    .grid-item-left {
      padding-right: 0;
    }
    .grid-item-right {
      padding-left: 0;
    }
  }
  //done in javascript ngIf
  /* @media screen and (max-width: $breakpoint-xs) {
    .content-box.content-box--aside {
      display: none;
    }
  }*/
}

@media screen and (min-width: $breakpoint-mdl-plus) {
  .no-desktop-title .app-page {
    app-title-bar {
      display: none;
    }
  }
}

.page-content {
  &.home-content {
    padding-bottom: 20px;
  }
  //LEFT COLUMN
  .page-left-column {
    width: 216px;
    position: absolute;
    left: -231px;
    top: 15px;
    z-index: 2;
    @media screen and (max-width: $breakpoint-mdl) {
      display: none;
    }
  }

  /*.content-box.height,
  .grid-item.grid-item--small .content-box.height,
  .grid-item.grid-item--big .content-box.height {
    height: 570px;
  }*/

  /* GRID ITEM */
  /* .grid-item.grid-item--verybig.grid-item--edito .content-box {
    min-height: 771px;
  }*/
  /*.grid-item.grid-item--verybig.grid-item--edito .content-box.height {
    height: 1500px;
  }*/

  /* .grid-item.grid-item--big.grid-item--wide .content-box {
    min-height: 285px;
  }*/
  /*.grid-item.grid-item--big.grid-item--wide .content-box.height {
    min-height: 570px;
  }*/
  //important to support the navigation
  //MM but must change for tablet

  /* .offer-page {
    .grid-item.grid-item--fullsize .content-box {
      height: auto;
      min-height: 125px;
    }
  }*/

  /* ---- grid sizes ---- */
  .grid {
    max-width: 1080px;
    margin: 0 auto 30px auto;
  }
  .grid-sizer {
    width: 12.5%;
  }
  .grid-item {
    width: 25%;
  }
  .grid-item--big {
    width: 37.5%;
  }
  .grid-item--big.grid-item--wide {
    width: 50%;
  }
  .grid-item--halfsize {
    width: 50%;
  }
  .grid-item--verybig {
    width: 75%;
  }
  .grid-item.grid-item--fullsize {
    width: 100%;
  }
  .grid-item {
    float: left;
    padding: 15px;
    position: relative;
  }

  //NESTED GRID
  .container--nested {
    padding: 15px;
    & > .content-box {
      padding-bottom: 15px;
    }
    @media screen and (max-width: $breakpoint-xs) {
      padding: 9px 6px;
      & > .content-box {
        padding-bottom: 9px;
      }
    }
  }
  /* CONTRATS */

  .grid-sizer--contract {
    width: 33.333%;
  }

  .grid-item--contract {
    width: 33.333%;
    float: left;
    padding: 9px;
    position: relative;
  }
  .grid-item--contract.grid-item--vehicule {
    width: 50%;
  }
  .grid-item--contract.grid-item--big {
    width: 66.666%;
  }
  /* VEHICULES */

  .grid-sizer--vehicule {
    width: 50%;
  }

  .grid-item--vehicule {
    width: 50%;
    float: left;
    padding: 9px;
    position: relative;
  }
  .grid-item--vehicule.grid-item--big {
    width: 100%;
  }

  /* ---- mediaqueries ---- */
  @media screen and (max-width: $breakpoint-mdl) {
    .container:not(.login-container) {
      padding: 15px;
      .grid:before {
        display: none;
      }
    }
  }
  @media screen and (max-width: $breakpoint-md) {
    .container:not(.login-container) {
      padding: 12px;
    }
    .grid-item {
      padding: 12px;
    }
    .grid-item:before {
      width: calc(100% - 24px);
    }
  }
  @media screen and (min-width: $breakpoint-smd + 1) {
    .grid-item.grid-item--wide .content-box__image {
      width: calc(50% - 12px);
      float: left;
      height: 100%;
    }
    .grid-item.grid-item--wide .content-box__image + .content-box-inner {
      width: calc(50% + 12px);
      float: left;
      height: 100%;
    }
  }
  @media screen and (min-width: $breakpoint-md + 1) {
    .grid-item.grid-item--wide .content-box__image {
      width: calc(50% - 15px);
    }
    .grid-item.grid-item--wide .content-box__image + .content-box-inner {
      width: calc(50% + 15px);
    }
  }

  @media screen and (max-width: $breakpoint-smd) {
    .side-duplicate {
      display: none;
    }
    .container:not(.login-container) {
      padding: 12px;
    }
    .grid {
      max-width: 1080px;
      margin: 0 auto 24px auto;
    }
    .grid-sizer,
    .grid-sizer--contract,
    .grid-sizer--vehicule {
      width: 8.33333%;
    }
    .grid-item {
      width: 33.333%;
      padding: 12px;
    }
    .grid-item.grid-item--small {
      width: 33.333%;
    }
    .grid-item.grid-item--big {
      width: 50%;
      //MMwidth: 33.333%;
    }
    .grid-item.grid-item--halfsize {
      width: 100%;
    }
    .grid-item.grid-item--verybig {
      width: 100%;
    }
  }
  @media screen and (max-width: $breakpoint-sm) {
    .grid-item--contract {
      width: 33.333%;
    }
    .grid-item--contract.grid-item--big {
      width: 66.666%;
    }
    .grid-item--vehicule {
      width: 100%;
    }
    .grid-item,
    .grid-item.grid-item--big,
    .grid-item.grid-item--small {
      width: 50%;
    }
    /* .grid-item.grid-item--big .content-box {
      min-height: 370px;
    }
    .grid-item.grid-item--big.grid-item--wide .content-box {
      min-height: 369px;
    }*/
    .grid-item--wide .content-box__image {
      width: 100%;
      height: 145px;
    }
  }
  @media screen and (max-width: $custom-breakpoint-mobile) {
    .grid-item--contract {
      width: 100%;
    }
    .grid-item--contract.grid-item--big {
      width: 100%;
    }
    .grid-item.grid-item--verybig.grid-item--edito
      .content-box
      .content-box__image {
      height: 300px;
    }
    /*.grid-item--contract .content-box,
    .grid-item--contract.grid-item--big .content-box,
    .grid-item--contract.grid-item--verybig .content-box*/
    /*,.grid-item .content-box,
    .grid-itemgrid-item--big .content-box,
    .grid-itemgrid-item--verybig .content-box */ /*{
      height: auto;
    }*/
  }
  @media screen and (max-width: $breakpoint-xs) {
    .container:not(.login-container) {
      padding: 15px;
    }
    .grid-item:before {
      width: 100%;
    }
    .grid-sizer,
    .grid-sizer--contract,
    .grid-sizer--vehicule,
    .grid-item {
      width: 25%;
    }
    .grid-item {
      padding: 0 0 15px 0;
    }
    .grid-item,
    .grid-item.grid-item--small,
    .grid-item.grid-item--big,
    .grid-item.grid-item--verybig {
      width: 100%;
    }
    .grid-item.grid-item--verybig.grid-item--edito
      .content-box
      .content-box__image {
      height: 240px;
    }
  }
  @media screen and (max-width: $breakpoint-xs) {
    .grid-item.grid-item--verybig.grid-item--edito
      .content-box
      .content-box__image {
      height: 200px;
    }
  }
}

.app-page:not(.template) {
  .grid-item,
  .grid-itemgrid-item--big,
  .grid-itemgrid-item--verybig,
  .grid-item--contract,
  .grid-item--vehicule,
  .grid-item--vehicule.grid-item--big,
  .grid-item--contract.grid-item--big,
  .grid-item--contract.grid-item--verybig,
  .grid-item.grid-item--verybig.grid-item--edito {
    .content-box {
      height: auto;
    }
  }
}
