@mixin button-theme($theme) {
  .side-button {
    @media screen and (min-width: $breakpoint-sm-plus) {
      padding-left: 30px;
      .button-label {
        white-space: nowrap !important;
      }
    }
  }
  button {
    &,
    &:active,
    &:focus,
    &:hover {
      position: relative;
      top: 0;
      left: 0;
    }
  }

  .mat-button-focus-overlay,
  .mat-button-ripple {
    visibility: hidden;
  }

  app-button {
    display: block;
    .button-with-icon {
      display: block;
      .mat-button-wrapper {
        display: block;
        position: relative;
        padding-left: 35px;
        width: 100%;
        app-icon {
          display: block;
          position: absolute;
          left: 0;
        }
      }
    }

    &.contact-button {
      button.button-with-icon {
        padding-top: 4px;
        padding-bottom: 4px;
        background-color: $color-accent !important;
        border-radius: 8px 0 0 0;
        .mat-button-wrapper {
          padding-left: 45px;
        }
        &:active,
        &:hover,
        &:focus {
          background-color: $grey-90 !important;
        }
        .button-label {
          line-height: 30px;
          font-size: 16px;
          display: block;
        }
        app-icon {
          width: 30px;
          height: 30px;
        }
      }
    }
    &.show-more-button {
      button.button-with-icon {
        @extend %text-subtitle;
        color: $grey-90;
        &:active,
        &:hover,
        &:focus {
          color: $color-accent;
        }
      }
      app-icon {
        width: 20px;
        height: 20px;
      }
    }
  }
  input[type="button"] {
    //!important to override ios native style
    background: none !important;
    background-color: $grey-50 !important;
    color: $white;
    text-transform: uppercase;
    border: none;
    width: auto;
    max-width: 100%;
    border-radius: 0 !important;
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    padding: 7px 20px;
    @extend %body-cap;
    min-height: 34px;
    &:active,
    &:hover,
    &:focus {
      background-color: $grey-70 !important;
      outline: 0;
    }
  }

  .app-fab-button .mat-fab .mat-button-wrapper {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
}
