@mixin textarea-theme($theme) {
  .custom-textarea[appearance="none"] > div.mat-form-field-wrapper {
    padding-bottom: 0 !important;
  }

  .custom-textarea.mat-form-field {
    div.mat-form-field-wrapper
      > div.mat-form-field-flex
      > div.mat-form-field-infix {
      padding-bottom: 0 !important;
      padding: 0 0 0.5em;
      border-top: none;
      overflow: visible;
    }

    div.mat-form-field-underline {
      display: none;
    }

    textarea.mat-input-element {
      padding: 0;
      margin: 0;
      color: $grey-90;
    }

    background-color: $grey-10;
    color: $grey-90;
    display: inline-block;
    width: 100%;

    margin-bottom: 0;

    padding-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 10px;
    border: none;
    /* label {
      @extend %text-small;
      padding-bottom: 10px;
      display: block;
      color: $grey-70;
      //transition: none;
      //-webkit-transition: none;
    }

      label:not(.textarea-empty) {
      @extend %text-small;
    }*/

    &:focus-within {
      border: 1px solid $grey-90;
      padding-top: 9px;
      padding-left: 14px;
      padding-right: 14px;
      padding-bottom: 9px;

      /*label {
        color: $grey-90;
        @extend %text-small;
      }*/
    }
    textarea {
      @extend %text-subtitle;
    }

    // label -> text-small
    // placeholder -> color grey-70
    // text primary
  }

  .hint-error {
    color: $color-warn;
  }

  .hint-left-side {
    float: left;
  }
}
