@import "./app/design-system/styles.scss"

$font-aw: Helvetica, sans-serif
$text-color: #25282a
$primary-color: #ff7900

body
    font: 62,5% $font-aw
    color: $text-color

.link-edit-icons
    font-family: 'Material Icons'
    font-weight: normal
    font-style: normal
    font-size: 24px
    line-height: 1
    letter-spacing: normal
    text-transform: none
    display: inline-block
    white-space: nowrap
    word-wrap: normal
    direction: ltr
    -webkit-font-feature-settings: 'liga'
    -webkit-font-smoothing: antialiased
    position: absolute
    top: 0px
    right: 5px
    padding: 0
    background-color: transparent
    border: none
    cursor: pointer

a.link-edit::after
    position: absolute
    top: 0
    right: 0
    display: inline-block
    line-height: 0

.position-relative
    position: relative
