@mixin textfield-theme($theme) {
  .mat-form-field {
    @extend %text-subtitle;
    display: block;
    position: relative;
    text-align: left;
    margin-bottom: 5px;
    // Couleur du caret / curseur . Faut-il la changer ?
    // input {
    //     caret-color: $bc2; // change the caret color (pointer)
    // }

    .mat-button-wrapper {
      display: block;
    }

    input {
      @extend %text-subtitle;
      color: $grey-90;
    }
    //to prevent to crop the icon in ie11
    .mat-icon-wrapper {
      display: block;
    }

    // Styling the label :
    // color of the label vs placeholder ?
    &.mat-focused label.mat-form-field-label,
    label.mat-form-field-label {
      @extend %text-small;
      color: $grey-70;
    }
    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $grey-70 !important;
      opacity: 1 !important; /* Firefox */
    }
    :-ms-input-placeholder {
      color: $grey-70 !important;
      opacity: 1 !important;
    }

    &.mat-form-field-should-float .mat-form-field-label-wrapper {
      overflow: visible; // allow the label to go near the top
      .mat-form-field-label {
        // 0.8125 = 13 / 16 (targetFontSize / regularFontSize
        //scale 1 : no text scale
        transform: translateY(-1.6em) scale(1) perspective(100px) translateZ(0);
      }
    }
    &._mat-animation-noopable {
      .mat-form-field-label {
        transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
          color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
          width 0.4s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
      }
    }

    /// Dimensions
    & > div.mat-form-field-wrapper {
      overflow: visible;
      //height: 46px;
      padding-bottom: 0; // must modify position of underline
    }

    input.mat-input-element {
      margin-top: 0;
    }

    .mat-form-field-infix {
      border-top: 10px solid transparent;
      padding: 4px 0 6px;
      width: 100%;
    }
    .mat-form-field-suffix {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      align-items: center;
    }

    div.mat-form-field-underline {
      background-color: $form-line-color-default;
      transform: none;
      height: 1px;
      bottom: 2px; // important : replaces the line moved by the padding-bottom: 0 of the mat-form-field-wrapper
    }
    &.mat-form-field-disabled.prefilled {
      div.mat-form-field-underline {
        display: none;
      }
      input.mat-input-element:disabled {
        color: $grey-90;
      }
    }

    &.mat-focused,
    &:hover {
      div.mat-form-field-underline {
        background-color: $form-color-on;
      }
    }
    .mat-form-field-ripple {
      display: none;
      background-color: transparent;
    }

    &.error {
      &.mat-focused.mat-form-field-should-float {
        div.mat-form-field-underline {
          background-color: $color-warn;
        }
      }
      div.mat-form-field-underline {
        background-color: $color-warn;
      }
      label.mat-form-field-label,
      input {
        color: $color-warn;
      }
      ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $color-warn;
        opacity: 1; /* Firefox */
      }
    }

    /* div.mat-form-field-underline > .mat-form-field-ripple {
      height: 0px; // Enlève l'effet qui souligne la bordure
    }*/
  }

  //for both select and textfield
  .app-select,
  .mat-form-field {
    & + .mat-hint,
    & + .mat-hint + .mat-hint {
      display: block;
      @extend %text-small;
      color: $color-primary;
      &.narrowed {
        padding-right: 42px;
      }
      &.hint-error {
        color: $color-warn;
      }
      span {
        display: block;
      }
    }
    & + .mat-hint + .mat-hint {
      margin-top: 0;
    }
    & + .mat-hint:last-of-type {
      margin-bottom: 5px;
    }

    &.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
      display: block;
      position: static;
      margin-top: 0;
    }
  }
}
