@mixin checkbox-theme($theme) {
  $primary: map-get($theme, primary);

  .mat-checkbox {
    display: inline-block;
    padding: 4px 0;
    text-align: left;
    
    .mat-checkbox-layout {
      display: inline-flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      -webkit-justify-content: flex-start;
      align-items: flex-start;
      width: auto;

      .mat-checkbox-inner-container {
        width: 16px;
        height: 16px;
        margin: 0 8px 0 0;
      }
    }

    .mat-checkbox-label {
      @extend %text-small;
      line-height: 18px !important;
      color: $form-color-default;
      white-space: normal;
      a.link {
        text-decoration: underline;
      }
    }

    .mat-checkbox-frame {
      border-color: $form-color-default;
      border-radius: 0;
      border-width: 1px;
    }
    .mat-checkbox-checkmark-path {
      stroke-width: 3.5px;
    }

    .mat-checkbox-checkmark {
      fill: $form-color-on;
      left: 50%;
      margin-left: -4px;
      top: 3px;
      width: 8px;
      height: 10px;
    }

    .mat-checkbox-mixedmark {
      background-color: transparent;
    }
    &:not(.mat-checkbox-disabled) {
      &.mat-checkbox-checked,
      &.cdk-focused,
      &:hover {
        &.mat-accent .mat-checkbox-background {
          background-color: transparent;
        }

        .mat-checkbox-frame {
          border-color: $form-color-on;
        }
        .mat-checkbox-label {
          color: $form-color-on;
        }

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          /* IE10+ CSS styles go here */
          /* Apply shadow correctly around cards for IE */
          .mat-checkbox-checkmark {
            top: 0;
          }
        }

        .mat-checkbox-checkmark-path {
          stroke: $form-color-on !important;
        }
      }
    }
  }

  .mat-checkbox-indeterminate.mat-accent {
    .mat-checkbox-background {
      background-color: transparent;
    }

    .mat-checkbox-frame {
      border: 1px solid $form-color-on !important;
    }

    .mat-checkbox-mixedmark {
      background-color: $form-color-on !important;
      width: calc(100% - 10px);
      height: 2px;
    }
  }

  .mat-checkbox-disabled {
    .mat-checkbox-label {
      color: $form-color-disabled;
    }

    .mat-checkbox-layout {
      .mat-checkbox-frame {
        z-index: 1;
        border-color: $form-color-disabled !important;
      }

      .mat-checkbox-checkmark-path {
        stroke: $form-color-disabled !important;
      }

      .mat-checkbox-background {
        background-color: rgba($form-color-disabled, 0.2);
      }
    }
  }

  .mat-checkbox:not(.mat-checkbox-disabled) {
    &.mat-primary .mat-checkbox-ripple .mat-ripple-element {
      background-color: rgba($form-color-default, 0.26);
    }

    &.mat-accent .mat-checkbox-ripple .mat-ripple-element {
      background-color: rgba($form-color-default, 0.26);
    }

    &.mat-warn .mat-checkbox-ripple .mat-ripple-element {
      background-color: rgba($form-color-default, 0.26);
    }
  }

  //for ie11
  @media all and (-ms-high-contrast: none),
    all and (-ms-high-contrast: active) {
    :not(.mat-checkbox-disabled).mat-checkbox-checked.mat-checkbox
      .mat-checkbox-checkmark,
    :not(.mat-checkbox-disabled).cdk-focused.mat-checkbox
      .mat-checkbox-checkmark,
    :not(.mat-checkbox-disabled).mat-checkbox:hover .mat-checkbox-checkmark {
      top: 3px;
    }
  }

  .mat-checkbox {
    &.color-white {
      .mat-checkbox-frame {
        border-color: $white-opacity;
      }
      .mat-checkbox-label {
        color: $white-opacity;
      }
      &:not(.mat-checkbox-disabled) {
        &.mat-checkbox-checked,
        &.cdk-focused,
        &:hover {
          .mat-checkbox-frame {
            border-color: $white;
          }
          .mat-checkbox-label {
            color: $white;
          }
          .mat-checkbox-checkmark-path {
            stroke: $white !important;
          }
        }
        &.cdk-focused,
        &:hover {
          .mat-checkbox-label {
            //text-decoration: underline;
          }
        }
      }
      &.mat-checkbox-disabled {
        .mat-checkbox-label {
          color: $white-opacity;
        }

        .mat-checkbox-layout {
          .mat-checkbox-frame {
            z-index: 1;
            border-color: $white !important;
          }

          .mat-checkbox-checkmark-path {
            stroke: $white !important;
          }

          .mat-checkbox-background {
            background-color: transparent;
          }
        }
      }
    }
  }
}
