//FORMULAIRES

.mobile-content-width {
  width: 290px;
}

.content-box--form {
  background: $white;
  position: relative;
  & > .content-box-inner {
    padding-top: 40px;
  }

  .layout-narrow {
    text-align: center;
    clear: both;
  }
  .layout-narrow,
  .layout-large {
    @extend %text-small;
    p {
      @extend %text-body;
    }
    p.mention,
    label {
      @extend %text-small;
    }
    label {
      display: flex;
      //margin-bottom: 10px;
      color: $grey-70;
    }
  }
}

.form-row-table {
  display: table;
  margin-bottom: 20px;
  .form-group {
    display: table-cell;
    padding-right: 20px;
    width: 50%;
  }
}

.form-group {
  margin-bottom: 20px;
  text-align: left;
  label {
    margin-bottom: 10px;
  }
}
.layout-large {
  .form-group:last-of-type {
    margin-bottom: 0;
  }
}

.form-title {
  @extend %title-cap-accent;
  margin-bottom: 30px;
  text-align: center;

  & + p.mention {
    margin-top: -25px;
    text-align: center;
  }
  + .form-subtitle {
    margin-bottom: 30px;
    & + .mention {
      margin-top: -25px;
    }
  }
}
.form-subtitle {
  @extend %app-subtitle;
  margin-bottom: 20px;
  & + .mention {
    margin-top: -15px;
  }
}
.form-heading {
  @extend %app-heading;
}

//globally style the button of app-form-buttons
//.form-buttons can be used in div container of links, for the vertical spacing
.form-buttons {
  & > div {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
  }
  margin-top: 30px;
  margin-bottom: 40px;
  //display: inline-block;
  app-button {
    display: inline-block;
    max-width: calc(50% - 15px);
    @media (max-width: $breakpoint-md) {
      max-width: calc(50% - 12px);
    }
    @media (max-width: $breakpoint-xs) {
      max-width: calc(50% - 7.5px);
    }

    // width: 50%;
    @extend %responsive-padding-half;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
  //Between 2 form-buttons, the space must be 30px and not 40px
  //so we retreive 10px  with margin bottom negative in the second one.
  //this happens in the login rating screen
  & + .form-buttons {
    margin-top: -10px !important;
  }
  &.long-button {
    @media (max-width: $breakpoint-xs) {
      & > div {
        flex-direction: column-reverse;
      }
      app-button {
        max-width: 100%;
        padding: 0;
        &:first-child {
          margin-top: 15px;
        }
      }
    }
  }
  &.single-button {
    app-button {
      max-width: 100%;
    }
  }
}

//DROP ZONE

.ngx-file-drop__drop-zone {
  height: auto !important;
  min-height: 64px;
  margin: auto;
  border: none !important;
  border-radius: 0 !important;
  @include dashed-bordered($grey-50);
}
.ngx-file-drop__content {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  min-height: 64px;
  height: auto !important;
  color: $grey-70 !important;
  @extend %text-small;
  padding: 10px 15px;

  .ngx-file-drop__browse-btn {
    margin: 15px;
  }
}
.mobile-tablet .ngx-file-drop__content {
  padding-top: 20px;
}
