@mixin list-item-theme($theme) {
  //all lists except navigation / nav-list which has its own theme mixin
  .mat-list-base {
    padding-top: 0 !important;
    .link-item {
      &.mat-list-item {
        .mat-list-item-content {
          padding: 0 10px 0 20px;
        }
      }
    }
  }
  .link-item {
    &.mat-list-item {
      .mat-list-item-content {
        display: flex;
        width: 100%;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px 0 20px;
        @media (max-width: $custom-breakpoint-mobile) {
          padding-right: 1px;
        }
      }
      &.focus,
      &:active {
        outline: 0 0;
      }
    }
  }
}
