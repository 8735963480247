@mixin nav-list-theme($theme) {
  //nav list item style

  .mat-list-item {
    &.selected,
    &:hover,
    &:focus,
    &:active {
      background: $grey-nav-menu !important;
    }
  }

  app-nav-menu {
    .mat-nav-list {
      background: $white;

      &.mat-list-base,
      &.mat-list-base .mat-list-item .mat-list-item-content {
        padding: 0;
      }
      &.mat-list-base {
        padding-bottom: 40px;
      }

      .mat-list-item {
        @extend %text-body;
        color: $grey-70;
        display: -webkit-flex;
        display: flex;
        width: 100%;
        -webkit-align-items: center;
        align-items: center;
        height: 50px;
        padding: 0 20px;
        background: $white !important;
        @media screen and (max-width: $breakpoint-md) {
          padding: 0 15px;
        }
        border-bottom: 1px solid $border-color;
        position: relative;
        //should inherit font size and color from body

        &.selected,
        &:hover,
        &:focus,
        &:active {
          background: $grey-nav-menu !important;
          &:before {
            content: "";
            display: block;
            position: absolute;
            width: 4px;
            height: 35px;
            left: 0;
            top: 7.5px;
            background-color: $grey-70;
          }
        }
        &.footer-link {
          background: $grey-90 !important;
          color: $white;
          border-bottom: none;

          &.selected,
          &:hover,
          &:focus,
          &:active {
            background: $grey-90 !important;
            &:before {
              background-color: $white;
            }
          }
        }
      }
    }
  }
  .dialog--pwaMenu {
    app-nav-menu .mat-nav-list {
      background: $grey-90;
    }

    .mat-nav-list {
      &.mat-list-base {
        padding-bottom: 0px;
      }
    }
  }
}
