//

/**** Colors ****/
$grey-05: #f7f7f7;
$grey-10: #f0f0f0;
$grey-30: #d7d7dc;
$grey-50: #a0a0a5;
$grey-70: #6e6e78;
$grey-80: #4a4a4a;
$grey-90: #282832;
$grey-nav-menu: #f7f7f7;
$white: #ffffff;
$black: #000000;
$orange: #ff7900;
$sarcelle: #23788c;
$magenta: #d20062;
$magenta-halftone-80: #db3281;
$grey-90-30: #ffffff;
$white-opacity: rgba($white, 0.8);
$overlaygrey-90-30: rgba(40, 40, 50, 0.3);

$default-icon-color: $sarcelle;

$border-color: $grey-30;
$body-bg-color: $grey-10;
$table_color-border: $grey-30;

$form-line-color-default: $grey-50;
$form-color-default: $grey-70;
$form-color-on: $grey-90;
$form-color-disabled: $grey-50;
$form-color-input: $grey-90;

$color-primary: $grey-90;
$color-accent: $orange;
$color-warn: $magenta;
$color-tertiary: $sarcelle; //?? yes ?  no ?

$color-renault: #ffcc33;

@mixin bg-alpha($color, $opacity) {
  background-color: rgba($color, $opacity);
}

.bg-grey-70 {
  background-color: $grey-70;
}
