@mixin responsive-margin($position, $important: "") {
  margin-#{$position}: 30px #{$important};

  @media screen and (max-width: $breakpoint-md) {
    margin-#{$position}: 24px #{$important};
  }
  @media screen and (max-width: $breakpoint-xs) {
    margin-#{$position}: 15px #{$important};
  }
}
%responsive-margin-right {
  @include responsive-margin("right");
}
%responsive-margin-right-important {
  @include responsive-margin("right", !important);
}

%responsive-margin-left {
  @include responsive-margin("left");
}
%responsive-margin-left-important {
  @include responsive-margin("left", !important);
}
//Only horizontal
%responsive-margin {
  @extend %responsive-margin-left;
  @extend %responsive-margin-right;
}

%responsive-margin-top {
  @include responsive-margin("top");
}
%responsive-margin-top-important {
  @include responsive-margin("top", !important);
}

%responsive-margin-bottom {
  @include responsive-margin("bottom");
}
%responsive-margin-bottom-important {
  @include responsive-margin("bottom", !important);
}
%responsive-padding-right {
  padding-right: 30px;
  @media screen and (max-width: $breakpoint-md) {
    padding-right: 24px;
  }
  @media screen and (max-width: $breakpoint-xs) {
    padding-right: 15px;
  }
}
%responsive-padding-left {
  padding-left: 30px;
  @media screen and (max-width: $breakpoint-md) {
    padding-left: 24px;
  }
  @media screen and (max-width: $breakpoint-xs) {
    padding-left: 15px;
  }
}
//Only horizontal
%responsive-padding {
  @extend %responsive-padding-left;
  @extend %responsive-padding-right;
}
//Only horizontal
%responsive-padding-half {
  padding-left: 15px;
  padding-right: 15px;
  @media screen and (max-width: $breakpoint-md) {
    padding-left: 12px;
    padding-right: 12px;
  }
  @media screen and (max-width: $breakpoint-xs) {
    padding-left: 7.5px;
    padding-right: 7.5px;
  }
}
//tout underline text as text-decoration underline
@mixin underline($color: $white, $height: 1px, $shadowColor: null, $bottom: 0) {
  position: relative;
  text-decoration: none;
  @if ($shadowColor) {
    text-shadow: 1px 0px 0 $shadowColor, -1px 0px 0 $shadowColor,
      0px 1px 0 $shadowColor;
  }

  z-index: 2;

  &:after {
    content: "";
    position: absolute;
    height: $height;

    background-color: $color;
    bottom: $bottom;
    left: 0;
    right: 0;
    z-index: -1;
  }
}
//border
@mixin plain-border(
  $position: left,
  $weight: 6px,
  $color: $color-accent,
  $full: true
) {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    background-color: $color;
    #{$position}: 0;
    @if ($position == left or $position == right) {
      width: $weight;
      @if ($full) {
        height: 100%;
        top: 0;
      }
    } @else {
      height: $weight;
      @if ($full) {
        width: 100%;
        left: 0;
      }
    }
  }
}

@mixin dashed-border(
  $position,
  $color: $border-color,
  $thickness: 1px,
  $isPlain: false,
  $dash: 4px,
  $gap: 4px
) {
  $color2: rgba(255, 255, 255, 0);
  @if ($isPlain == true) {
    $color2: $color;
  }
  $to: right;
  background-position: $position;
  @if ($position == top or $position == bottom) {
    background-size: $dash + $gap $thickness;
    background-repeat: repeat-x;
  } @else {
    $to: bottom;
    background-size: $thickness $dash + $gap;
    background-repeat: repeat-y;
  }
  background-image: linear-gradient(to $to, $color 50%, $color2 0%);
  border-radius: 1px;
}

@mixin dashed-bordered($color: $border-color) {
  @include dashed-border(top, $color);
  position: relative;
  & > div {
    position: relative;
    z-index: 2;
    & > * {
      z-index: 2;
    }
    @include dashed-border(bottom, $color);
    &:after,
    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: 1;
    }
    &:after {
      @include dashed-border(right, $color);
    }
    &:before {
      @include dashed-border(left, $color);
    }
  }
}

@mixin float-border(
  $color: $color-90,
  $thickness: 1.3px,
  $length: 10px,
  $position: bottom
) {
  @include dashed-border($position, $color, $thickness, true, $length);
  background-size: none;
}

@mixin chevron(
  $direction: "down",
  $color: $grey-90,
  $thickness: 1.6px,
  $leftBorderSize: 10px,
  $rightBorderSize: 10px,
  $width: 16.5px,
  $height: 14px,
  $rounded: true,
  $gap: -1.8px
) {
  @if ($rightBorderSize==null) {
    $rightBorderSize: $leftBorderSize;
  }
  @if ($direction== "left") {
    transform: rotate(90deg);
  } @else if ($direction== "right") {
    transform: rotate(-90deg);
  } @else if ($direction== "up") {
    transform: rotate(180deg);
  }
  position: relative;
  display: -webkit-flex;
  display: flex;
  width: $width;
  height: $height;
  &:before {
    content: "";
    display: inline-block;
    width: $leftBorderSize;
    height: $thickness;

    @include float-border($color, $thickness, $width);
    transform: rotate(225deg);
    @if ($rounded == true) {
      border-radius: $thickness;
    }
    margin: 6px $gap 0 0;
  }
  &:after {
    content: "";
    display: inline-block;
    width: $leftBorderSize;
    height: $thickness;
    @include float-border($color, $thickness, $width);
    transform: rotate(-225deg);
    @if ($rounded == true) {
      border-radius: calc($thickness / 2)
    }
    margin: 6px 0 0 $gap;
  }
}

@mixin chevron-small($direction: "down", $color: $grey-90) {
  @include chevron(
    $direction,
    $color,
    1.6px,
    6.5px,
    6.5px,
    11px,
    7.2px,
    true,
    -1.2px
  );
}
@mixin chevron-big($direction: "down", $color: $grey-90) {
  @include chevron(
    $direction,
    $color,
    1.6px,
    18px,
    18px,
    20px,
    14px,
    true,
    -1.9px
  );
}

@mixin colorize-chevron($color) {
  &:after,
  &:before {
    background-image: -webkit-gradient(
      linear,
      left top,
      right top,
      color-stop(50%, $color),
      color-stop(0%, $color)
    );
    background-image: linear-gradient(to right, $color 50%, $color 0%);
  }
}
@mixin circle($width, $bgColor, $strokeColor: null, $strokeWidth: 1px) {
  display: block;
  width: $width;
  height: $width;
  @if ($bgColor) {
    background-color: $bgColor;
  }
  @if ($strokeColor) {
    border: $strokeWidth solid $strokeColor;
  }
  border-radius: calc($width / 2);
}
@mixin triangle($baseWidth, $direction, $color, $hypWidth: null) {
  border: $baseWidth solid transparent;
  width: 0;
  height: 0;
  //equilateral
  @if ($hypWidth == null) {
    $hypWidth: $baseWidth;
  }
  @if $direction == "right" {
    border-left: $hypWidth solid $color;
    border-right-width: 0;
  } @else if $direction == "left" {
    border-right: $hypWidth solid $color;
    border-left-width: 0;
  } @else if $direction == "up" {
    border-bottom: $hypWidth solid $color;
    border-top-width: 0;
  } @else if $direction == "down" {
    border-top: $hypWidth solid $color;
    border-bottom-width: 0;
  }
}
