@mixin select-theme($theme) {
  // Styles of the select Panel
  .mat-select-panel-wrap {
    .mat-select-panel {
      border-radius: 0;
      .mat-option {
        @extend %text-subtitle;
        @extend %color-90;
      }
      .mat-option:not(:last-child) {
        border-bottom: 1px solid $border-color;
      }

      .mat-option:hover,
      .mat-option:active,
      .mat-option.mat-active,
      .mat-option:focus,
      .mat-option.mat-selected {
        color: $color-accent;
        background-color: $white;
      }
    }
  }

  // Styles of the select input
  .mat-form-field {
    width: 100%;
    .mat-select-value {
      @extend %text-subtitle;
      @extend %color-90;
    }

    .mat-select-arrow {
      border: none;

      margin-top: 0 0 -6px;
      @include chevron();
    }
    /* &.mat-form-field-has-label {
      .mat-select-arrow {
        @include chevron("up");
      }
    }*/
    &.error {
      .mat-select-value-text {
        color: $color-warn;
      }
      .mat-select-arrow {
        @include chevron("down", $color-warn);
      }
      /*&.mat-form-field-has-label {
        .mat-select-arrow {
          @include chevron("up", $color-warn);
        }
      }*/
    }

    .mat-form-field-underline,
    .mat-form-field-subscript-wrapper {
      position: initial; // IMPORTANT : so the hint text does not overflow below
    }
  }
}
