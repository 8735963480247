@mixin expansion-panel-theme($theme) {
  .app-accordion {
    .collapse-link {
      border-top: 1px solid $border-color;
      padding: 0;
    }

    .mat-accordion .mat-expansion-panel {
      &:last-of-type,
      &:first-of-type {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
    .mat-expansion-panel-spacing {
      margin: 0;
    }
  }
  .collapse-link,
  .app-tabs {
    .mat-expansion-panel-body {
      padding: 0;
    }
    .mat-expansion-panel {
      box-shadow: none;
      border-radius: 0;
    }
  }
  .collapse-link {
    .mat-expansion-indicator::after {
      color: $grey-90;
      padding: 5px;
    }
    .mat-expansion-panel-header-title {
      color: $grey-90;
    }
    .mat-expansion-indicator {
      &:after {
        border: none;
        padding: 0;
      }
      @include chevron-big();
      @media (max-width: $custom-breakpoint-mobile) {
        margin-right: 10px;
      }
    }
    .mat-expansion-panel-body {
      //we customize the padding in the content component
      padding: 0;
    }
    .mat-expansion-panel:not(.radio-checked) {
      .mat-expansion-panel-header:hover {
        .mat-expansion-panel-header-title {
          color: $color-accent;
        }
        .mat-expansion-indicator {
          @include chevron-big("down", $color-accent);
        }
      }
      .mat-expansion-panel-header:focus,
      .mat-expansion-panel-header:active {
        .mat-expansion-panel-header-title {
          color: $grey-90;
        }
        .mat-expansion-indicator {
          @include chevron-big("down", $grey-90);
        }
      }
    }
    .mat-expansion-panel.radio-checked {
      .mat-expansion-panel-header.mat-expanded {
        cursor: default;
        .mat-radio-label {
          cursor: default;
        }
      }
    }
  }
  .app-tabs {
    .mat-expansion-panel-header-description {
      margin-right: 0;
    }
  }

  .faq-list-item:last-of-type > .collapse-link {
    .collapse-link--panel {
      border-bottom: none;
    }
  }

  .faq-list-item > .collapse-link,
  .projects-filter-mobile > .collapse-link {
    padding-right: 0;
    padding-left: 0;
    .mat-expansion-panel-header {
      @extend %responsive-padding;
    }

    .mat-expansion-panel.mat-expanded {
      .mat-expansion-panel-header {
        background-color: $grey-10 !important;
      }
    }
    .mat-expansion-panel-header {
      &:focus,
      &:hover,
      &:active {
        .mat-expansion-panel-header-title {
          color: $grey-90;
        }
        .mat-expansion-indicator {
          @include chevron-big("down", $grey-90);
        }
      }
    }
  }
  .faq-list-item > .collapse-link {
    .mat-expansion-panel-header {
      .mat-expansion-panel-header-title {
        text-transform: none;
      }
    }
    .mat-expansion-panel-header,
    .collapse-link--panel {
      border-bottom: 1px solid $border-color;
    }
  }
  .projects-filter-mobile > .collapse-link {
    .mat-expansion-panel.mat-expanded {
      .mat-expansion-panel-header {
        border-top: 1px solid $border-color;
        padding-top: 0;
      }
    }
    .mat-expansion-panel-header {
      // border-top: 1px solid $border-color;
      padding-top: 1px;
    }
  }
  .faq > .mobile-faq > .collapse-link {
    @media (max-width: $breakpoint-sm) {
      padding-right: 0;
      padding-left: 0;
    }
    & > .mat-expansion-panel {
      & > .mat-expansion-panel-header,
      .collapse-link--panel {
        border-bottom: 1px solid $border-color;
      }
      & > .mat-expansion-panel-header {
        @extend %responsive-padding;
        background-color: $grey-90 !important;
        .mat-expansion-panel-header-title {
          color: $white;
          text-transform: none;
        }
        .mat-expansion-indicator {
          @include chevron-big("down", $white);
        }
      }

      & > .mat-expansion-panel-header {
        &:focus,
        &:hover,
        &:active {
          .mat-expansion-panel-header-title {
            color: $white;
          }
          .mat-expansion-indicator {
            @include chevron-big("down", $white);
          }
        }
      }
    }
  }
}
