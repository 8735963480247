@mixin radio-color($color) {
  .mat-radio-outer-circle {
    border-color: $color;
  }
  .mat-radio-inner-circle {
    background-color: $color;
  }
  .mat-radio-label-content {
    color: $color;
  }
  .mat-radio-ripple .mat-ripple-element {
    background-color: rgba($color, 0.15);
  }
}

@mixin radio-theme($theme) {
  .mat-radio-button {
    padding: 0;

    .mat-radio-container {
      margin: auto;
      width: 16px;
      height: 16px;
    }

    .mat-radio-inner-circle {
      height: 20px;
      width: 20px;
      left: -2px;
      top: -2px;
    }

    .mat-radio-outer-circle {
      border-width: 1px;
      height: 16px;
      width: 16px;
    }

    .mat-radio-label-content {
      @extend %text-small;
      padding-right: 16px;
    }

    &.mat-primary,
    &.mat-accent {
      @include radio-color($form-color-default);
    }

    &.mat-warn {
      @include radio-color($color-warn);
    }
    &.color-white,
    &.mat-warn.color-white {
      @include radio-color($white);
      &:not(.mat-radio-disabled) {
        &.mat-radio-checked,
        &.cdk-focused,
        &:hover {
          @include radio-color($white);
        }
      }
    }

    &:not(.mat-radio-disabled) {
      &.mat-radio-checked,
      &.cdk-focused,
      &:hover {
        @include radio-color($form-color-on);
      }
    }

    &.mat-radio-disabled {
      @include radio-color($form-color-disabled);
      .mat-checkbox-background {
        background-color: rgba($form-color-disabled, 0.15);
      }
    }
  }
}
