html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

body {
  position: relative;
  background-color: $body-bg-color;
  margin: 0;
  padding: 0;
  color: $grey-70;
  @extend %body-font-family;
  @extend %text-body;
}

//to test focus
/**:focus,
*.mat-focused,
*.cdk-focused {
  outline: dashed 1px $grey-30 !important;
}*/

//enlarge the clicking zone create bugs in ie11
.ie11,
.ios7,
.ios8 {
  .icon-button .icon-wrapper {
    display: inline;
    margin: 0 !important;
    padding: 0 !important;
  }
}

.text-bold,
strong {
  @extend %text-bold;
}

.text-black,
.value {
  @extend %text-bold;
  color: $grey-90;
}

.separator {
  height: 1px;
  background-color: $border-color;
  display: block;
  margin: 0;
  &.separator-40 {
    margin: 40px 0;
  }
  &.separator-30 {
    margin: 30px 0;
  }
  &.separator-20 {
    margin: 20px 0;
  }
  &.separator-10 {
    margin: 10px 0;
  }
}

.page-content {
  background-color: $grey-10;
}
//only for the demo
.form-group-demo-inversed {
  margin-right: -24px;
  margin-left: -24px;
  padding: 24px;
  background: $grey-70;
}

p {
  margin: 0 0 10px 0;
  color: $grey-70;
  &:last-of-type {
    margin-bottom: 30px;
  }
}

a.link,
.link {
  color: inherit;
  text-decoration: underline;
  &.bold {
    font-weight: 700;
  }
  &:hover,
  &:focus,
  &:active {
    color: $color-accent;
  }
  &:focus {
    outline: 0;
  }
  &:hover {
    text-decoration: underline;
  }
  &.link-white-warn {
    @include underline($grey-30, 1px, $color-warn);
    &,
    &:hover,
    &:focus,
    &:active {
      color: $white;
      text-decoration: none;
    }
    &:hover,
    &:focus,
    &:active {
      @include underline($white, 1px, $color-warn);
    }
  }
}

.negative a.link,
.negative a,
.link-white,
.link.link-white {
  //@include link-underlined($white, 1px, 0);
  color: $white-opacity;
  text-decoration: underline;
  &:hover,
  &:focus,
  &:active {
    //@include link-underlined($white, 2px, 0);
    color: $white;
  }
  /*&:focus,
  &:active {
    //special behaviour on focus? on hover ?
  }*/
}

.link-body-text {
  color: $grey-90;
  text-decoration: none;
  @include link-underlined($color-accent, 2px, auto);
  white-space: nowrap;
  &:hover,
  &:active,
  &:focus {
    color: $color-accent !important;
    text-decoration: none;
    cursor: pointer;
  }
}

//fix the back to button in arrow
.back-to-button {
  .mat-button-wrapper {
    display: block;
  }
}

.app-heading {
  @extend %app-heading;
  margin-bottom: 10px;
}

//PAGES
//titles mostly directly in pages component,  in grid-item--verybig or in grid-item--fullsize
.app-title {
  @extend %title-cap-bold-primary;
  margin-bottom: 20px;
}
.app-subtitle {
  @extend %app-subtitle;
  margin-bottom: 20px;
}
.app-small-title {
  @extend %small-title-bold;
  margin-bottom: 10px;
  margin-top: 30px;
  color: $grey-90;
}

h3.app-subtitle-cap {
  @extend %subtitle-cap;
  margin-bottom: 10px;
  margin-top: 20px;
  color: $grey-90;
  text-indent: 20px;
}
h4.app-text-body-bold {
  @extend %text-body-bold;
  margin-bottom: 10px;
  margin-top: 20px;
  color: $grey-90;
  text-indent: 20px;
}
p + h4.app-text-body-bold {
  margin-top: -10px;
}
h5.app-body-cap {
  @extend %body-cap;
  margin-bottom: 10px;
  margin-top: 20px;
  font-weight: 400;
  color: $grey-90;
}

.welcome-title {
  @extend %title-underlined-centered-accent;
  text-transform: none !important;
  margin-bottom: 10px;
  padding-right: 10px;
  padding-left: 10px;
}

//NEGATIVE - TEXT WHITE
.negative {
  color: $white-opacity;
  .app-subtitle,
  .form-subtitle,
  p {
    color: $white-opacity;
  }
}

.title-underlined-centered-accent {
  @extend %title-underlined-centered-accent;
}
//BLOCKS
//cards etc
.small-block-title {
  @extend %small-block-title;
}
.small-block-subtitle {
  @extend %small-block-subtitle;
}

.block-content-box {
  padding-right: 20px;
  padding-left: 20px;
}

//PAGES LOGIN ET REGISTER

.login-content {
  .content-box .content-box-inner {
    padding-top: 50px;
    padding-bottom: 0;
  }
}

// BLOCK RICH TEXT FROM CMS
.block-text-container {
  padding-top: 50px;
}
.block-text {
  max-width: 307px;
  ol {
    list-style-type: none;
    counter-reset: steps;

    li {
      margin: 0 0 10px 0;
      &:last-of-type {
        margin-bottom: 30px;
      }
      &:before {
        counter-increment: steps;
        content: counter(steps) ". ";
      }
    }
  }
}

ul.list-with-hyphens {
  padding-inline-start: 0.5em;
  li {
    margin-bottom: 0.7em;
    text-indent: -0.5em;
    &:before {
      content: "- ";
    }
  }
}

//style de popin possible content

.popin-content {
  p {
    text-align: center;
  }
  ul {
    text-align: center;
    color: $grey-70;
  }
  p + ul {
    margin-top: -10px;
  }
}

.login-container {
  app-back-to {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
  }
}

//BLOCK ROW CONTAINER
/* block-row components must be encapuslate in a div with class block-row-container*/
.block-row-container {
  @extend %responsive-padding;
}

//GLOBAL  SHOW MORE BUTTON CONTAINER
//nested-layout component and agreement history component
.show-more-button-box {
  border-top: 1px solid $border-color;
  height: 50px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
}

//TABLES BENEATH SWITCH CONTAINER

// app-tabs + app-table,
// app-switch-container + app-table {
//   padding-right: 0 !important;
//   padding-left: 0 !important;
//   mat-paginator {
//     padding: 0 40px;
//   }
// }

app-title-bar + app-table table.app-table.mat-table caption span {
  padding-top: 10px !important;
}
app-title-bar {
  // min-height: 39px;
}

.pwa app-pwa-form-back-link + .content-box--form .content-box-inner {
  padding-top: 50px;
}
