@mixin table-theme($theme) {
  // @import "./src/theme/utilities";

  table.mat-table.app-table {
    .action-group span {
      white-space: nowrap;
    }
    .action-groups {
      justify-content: flex-end;
    }
    @media (max-width: $custom-breakpoint-mobile) {
      .action-group span {
        display: inherit;
      }
    }
  }
}

.mat-paginator {
  .mat-paginator-page-size,
  .mat-paginator-navigation-first,
  .mat-paginator-navigation-last,
  svg {
    display: none;
  }
  .mat-paginator-container {
    min-height: 64px;
    padding: 0;
    @media (max-width: $breakpoint_md) {
      min-height: 96px;
    }
  }
  .mat-icon-button {
    border: solid 1px $grey-90;
    border-radius: 1px;
    width: 24px;
    height: 24px;
    position: relative;
    &:hover {
      border-color: $color-accent;
    }
    &[disabled] {
      border-color: $grey-70;
    }
    .mat-button-wrapper {
      top: calc(50% - 7px);
      position: absolute !important;
    }
    @media (max-width: $breakpoint_md) {
      width: 40px;
      height: 40px;
    }
  }
  .mat-paginator-range-label {
    @extend %text-small;
    color: $grey-90;
    margin: 0 12px 0 12px;
  }
  .mat-paginator-navigation-previous {
    .mat-button-wrapper {
      @include chevron("left", $grey-90);
      left: 2px;
      @media (max-width: $breakpoint_md) {
        left: 9px;
      }
    }
    &:hover .mat-button-wrapper {
      @include chevron("left", $color-accent);
    }
    &[disabled] {
      .mat-button-wrapper {
        @include chevron("left", $grey-50);
      }
    }
  }
  .mat-paginator-navigation-next {
    margin-left: 3px;
    .mat-button-wrapper {
      @include chevron("right", $grey-90);
      left: 4px;
      @media (max-width: $breakpoint_md) {
        left: 12px;
      }
    }
    &:hover .mat-button-wrapper {
      @include chevron("right", $color-accent);
    }
    &[disabled] {
      .mat-button-wrapper {
        @include chevron("right", $grey-50);
      }
    }
  }
  // &.expandable-table-paginator {
  //   padding: 0 40px;
  // }
}
