@mixin mat-dialog-theme($theme) {
  //center the popin dialog vertically
  .dialog--popin .cdk-global-overlay-wrapper {
    align-items: center !important;
    justify-content: center !important;
  }
  .dialog--pwaMenu {
    .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
      opacity: 0;
    }
  }
  .cdk-overlay-pane {
    .mat-dialog-container {
      box-shadow: none;
    }
    &.term-of-use-style {
      //@extend %responsive-margin-top-important;
      //@extend %responsive-margin-bottom;
      @extend %responsive-margin-right;
      @extend %responsive-margin-left-important;
      margin-top: 4% !important;
      height: 92%;

      .mat-dialog-title {
        margin-bottom: 30px;
        @extend %title-underlined-centered-accent;
        @extend %body-font-family;
      }

      .mat-dialog-container {
        color: $grey-90;
        @extend %body-font-family;
        padding: 0;
        overflow: visible;
        max-height: 92%;
        /*max-height: calc(100% - 60px);
        @media screen and (max-width: $breakpoint-md) {
          max-height: calc(100% - 48px);
        }
        @media screen and (max-width: $breakpoint-xs) {
          max-height: calc(100% - 30px);
        }*/
      }
      .mat-dialog-actions {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
        justify-content: center;
        padding-top: 30px;
        padding-bottom: 40px;
      }

      .mat-dialog-content {
        //140px = 15px - 40px - 35px - 40px);
        max-height: calc(100% - 150px);
        height: calc(100% - 150px);
      }
      @media screen and (max-width: $breakpoint-md) {
        bottom: 0;
        margin-top: 24px !important;
        height: calc(100% - 24px);
        max-height: 100%;
        .mat-dialog-container {
          max-height: 100%;
          height: calc(100% - 24px);
        }
        .mat-dialog-content {
          //retrieve title and buttons height
          max-height: calc(100% - 130px);
          height: calc(100% - 130px);
        }
        .mat-dialog-actions {
          padding-top: 20px;
        }
      }
      @media screen and (max-width: $breakpoint-xs) {
        margin-top: 15px !important;
        height: calc(100% - 15px);

        .mat-dialog-container {
          height: calc(100% - 15px);
        }
      }
    }
    &.contact-style {
      @extend %responsive-margin;
      .mat-dialog-container {
        position: relative;
        border-radius: 0;
        padding: 0;
        overflow: visible;
      }
      @media screen and (max-width: $breakpoint-sm) {
        height: calc(100% - 60px) !important;
        .mat-dialog-container {
          max-height: 100%;
        }
        .mat-dialog-content {
          max-height: calc(100vh - 90px);
        }
      }
    }

    &.menu-style,
    &.pwaMenu-style {
      margin: 0;
      .mat-dialog-container {
        position: relative;
        border-radius: 0;
        padding: 0;
        overflow: visible;
        color: $grey-70;
      }
      @media screen and (max-width: $breakpoint-md) {
        height: calc(100% - 60px) !important;
        .mat-dialog-container {
          max-height: 200%;
        }
        .mat-dialog-content {
          max-height: calc(100vh - 60px);
        }
      }
    }
    &.pwaMenu-style {
      height: calc(100% - 60px) !important;
      top: 0 !important;
      .mat-dialog-content {
        max-height: calc(100vh - 60px);
      }
    }
    &.popin-style {
      @extend %responsive-margin-right;
      @extend %responsive-margin-left-important;
      max-height: calc(100vh - 30px);
      .mat-dialog-container {
        padding: 0;
        .mat-dialog-content {
          max-height: calc(100vh - (185px + 30px));
        }
      }
    }
    &.feed-style {
      height: 100%;
      .mat-dialog-container {
        padding: 0;
        border-radius: 0;
        overflow: visible;
        .mat-dialog-content {
          margin: 0;
          padding: 0;
          max-height: calc(100vh - (120px + 50px));
          @media screen and (max-width: $breakpoint-mdl) {
            max-height: calc(100vh - (80px + 50px));
          }
          @media screen and (max-width: $breakpoint-sm) {
            max-height: calc(100vh - (60px + 50px));
          }
        }
      }
    }
  }

  .pwa.dialog--pwaMenu {
    .cdk-overlay-backdrop {
      bottom: 60px;
    }
  }
  .pwa.dialog--pwaMenu,
  .pwa.dialog--feed,
  .pwa.dialog--contact {
    .cdk-overlay-container {
      top: 0 !important;
    }
  }
  .pwa.dialog--feed {
    .cdk-overlay-pane.feed-style {
      max-width: 100% !important;
      height: 100% !important;
      width: 100%;
    }
  }

  .pwa.dialog--contact {
    .cdk-overlay-pane.contact-style {
      max-width: 100% !important;
      height: 100% !important;
      width: 100%;
    }
  }
}
