//in the grid format desktop

//content blocks
//grid-item                             -> with: 25%
//.grid-item--small                     -> width 25% for quick links, have a smaller fixed height
//grid-item---big                       -> width: 37.5% visual block with image

//horizontal in desktop and vertival in tablets / mobiles
//.grid-item--contract.grid-item--big   -> width 66.666% (2/3) multi cards
//grid-item---big.grid-item---wide      -> width: 37.5% vehicule or multi cards block with image

//bigger containers
//grid-item---verybig                   -> middle column content wdth: 75% in desktop
//grid-item---fullsize                  -> wdth: 100% in desktop, without right colummn

//content box with responsive padding
//full 100%
//used in header and footer too
//the width can be reduced adding the classes .layout-xsmall and .layout.narrow
.content-box-inner {
  // padding-top: 15px;
  // padding-bottom: 15px;
  max-height: 100%;
  max-width: 100%;
  @extend %responsive-padding;
}

.block-section:last-of-type {
  margin-bottom: 50px;
}

.pwa .sub-page .page-content .container,
.pwa .sub-page .page-content .grid-sizer + .grid-item {
  padding-top: 0;
}

.rwd-margin {
  @extend %responsive-margin;
}
.rwd-padding {
  @extend %responsive-padding;
}

//offset in pages row and all the page elements aligned with the row content
//only tablet - large mobile - and desktop
.content-box-offset {
  @media (min-width: $custom-breakpoint-mobile_plus) {
    padding-right: 20px;
    padding-left: 20px;
  }
}

//visually, the main container in pages is max 1300px - we have to report it on the header and the footer
//except login and registration pages
.effective-container,
.app-page .top-footer .content-box-inner-section {
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;
}

.page-content {
  //for easy readable content
  //and in the signin page too
  .layout-narrow {
    max-width: $narrow_width;
    margin: 0 auto;
  }
  //for forms
  .layout-xsmall {
    max-width: $xsmall_width; //? 300 or 320
    //max-width: 300px;
    margin: 0 auto;
  }

  .content-box {
    //no padding : as an example contains as direct child the left navigation list and the quick links
    padding: 0;
    position: relative;
    overflow: hidden;
  }
  //for the quick links ?? maybe can be removed when teh quick links will be done
  .grid-item--small .content-box {
    height: 170px;
  }
  //this min height seems to be obligatory when there is a left navigation so not for tablet and mobile ?? look at this at the end
  .grid-item.grid-item--fullsize.main-content .content-box {
    min-height: 350px;
  }

  //images in blocks
  .content-box__image {
    background-color: rgba(0, 0, 0, 0.05);
    background-size: cover;
    background-position: center center;
    height: 145px;
    background-repeat: no-repeat;
  }
  /*for login page but somewhere else maybe*/
  .content-box--background-image {
    background-color: rgba(0, 0, 0, 0.05);
    background-size: cover;
    .content-box-inner {
      width: 50%;
    }
  }
  //images in big blocks
  .grid-item--wide .content-box__image {
    background-size: contain;
  }
  //images in edito very big block
  .grid-item.grid-item--verybig.grid-item--edito
    .content-box
    .content-box__image {
    height: 368px;
  }
}

//mobile

.grid-item.grid-item--verybig,
.grid-item.grid-item--fullsize {
  & > .content-box {
    @media screen and (max-width: $breakpoint-sm) {
      margin-right: -24px;
      margin-left: -24px;
    }
    @media screen and (max-width: $breakpoint-xs) {
      margin-right: -15px;
      margin-left: -15px;
    }
  }
}
