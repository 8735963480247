$breakpoint_lg: 1325px;
$breakpoint_mdl: 1120px;
$breakpoint_md: 1024px;
$breakpoint_smd: 896px;
$breakpoint_sm: 767px;
$breakpoint_xs: 480px;

$breakpoint_lg_plus: 1326px;
$breakpoint_mdl_plus: 1121px;
$breakpoint_md_plus: 1025px;
$breakpoint_smd_plus: 897px;
$breakpoint_sm_plus: 768px;
$breakpoint_xs_plus: 481px;

$custom-breakpoint-mobile: 619px;
$custom-breakpoint-mobile_plus: 620px;

$narrow_width: 512px;
$xsmall_width: 320px;

@import "colors";
@import "typography";
@import "mixins";
