@mixin datepicker-theme($theme) {
  $primary: $color-primary; //map-color(map-get($theme, primary));
  $accent: $color-accent; //map-color(map-get($theme, accent));
  $warn: $color-warn; //map-color(map-get($theme, warn));
  $disabled: $grey-50;

  .mat-form-field.datepicker {
    //to place correctly the calendar icon in the field
    .mat-form-field-suffix,
    button {
      width: 30px;
      height: 30px;
    }
    .mat-button-focus-overlay {
      display: none;
    }
    &.mobile button {
      pointer-events: none;
    }
  }

  .mat-datepicker-content {
    border: 10px solid rgba($grey-90, 0.15);
    border-radius: 0 !important;
    .mat-calendar {
      width: 300px !important;
      height: auto !important;
    }
    .mat-calendar-header {
      .mat-calendar-body-label,
      .mat-calendar-period-button {
        @extend %text-subtitle;
        color: $grey-70;
      }
    }
    .mat-calendar-table-header-divider {
      display: none;
    }

    .mat-calendar-controls {
      margin: 0;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      align-items: center;
      padding-right: 16px;
      .mat-calendar-period-button {
        .mat-button-wrapper {
          display: -webkit-flex;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          width: auto;
          -webkit-align-items: center;
          align-items: center;
          border-bottom: 1px solid $grey-50;
          text-transform: lowercase;
          @extend %text-headline;
          color: $grey-90;
        }
      }
      .mat-calendar-next-button::after,
      .mat-calendar-previous-button::after {
        //reset
        border-left-width: 0;
        transform: none;
        position: static;
        margin: 0;
        border: none;
        border-top-width: 0;
      }
      .mat-calendar-next-button {
        @include chevron("right");
        margin-left: 15px;
        margin-right: -2px;
      }

      .mat-calendar-previous-button {
        @include chevron("left");
      }
    }

    .mat-calendar-arrow {
      border: none;
      margin: 0 0 -5px 8px;
      width: 13px;

      @include chevron();
      &.mat-calendar-invert {
        @include chevron("up");
      }
    }
    .mat-calendar-table-header th {
      padding: 8px 0 0 0;
    }

    .mat-calendar-body {
      .mat-calendar-body-label {
        visibility: hidden;
        padding-top: 0;
        &[colspan="7"] {
          display: none;
        }
      }

      th {
        .mat-calendar-body-cell-content {
          @extend %text-small;
          color: $grey-70;
          padding-bottom: 0;
        }
      }
      td {
        .mat-calendar-body-cell-content {
          @extend %text-subtitle;
        }
      }
    }
  }
  .mat-calendar-body-cell-content {
    &.mat-calendar-body-selected {
      background-color: $accent;
    }
    &.mat-calendar-body-today:not(.mat-calendar-body-selected) {
      border: none;
      color: $accent;
      /*&:hover {
          border: 1px solid $accent;
          background: none;
        }*/
    }
  }
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled) {
    &:hover,
    &:focus {
      .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
        background-color: $grey-50 !important;
        color: $white;
      }
    }
  }
  .mat-calendar-body-today.mat-calendar-body-selected {
    box-shadow: none;
  }
}
